import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserTokenService } from './pages/user-token.service'

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {
  
  
  constructor(private httpClient:HttpClient) { }

  public downloadFile(filetype,file,org_id,period_end){
    const bt = localStorage.getItem('bdcToken');
    return this.httpClient.get(`https://www.regulatorysolutions.us/data/downloadFile/` +  org_id + '/' + filetype + '/' + `'${period_end}'` + '/' + file,
    {headers: {'Authorization': 'Bearer ' + bt},
    observe:'response',responseType:'blob'})

  }

}
