<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><img src="../../../../assets/images/regulatory-solutions-logo.svg" alt="Regulatory Solutions" style="width:100px;height:100px" /></a>
  </div>
  <!--<nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>-->
  <div class='period-bar' style="width:250px;">
    <span>
      <div class="form-group">
      <label >Period End Date:</label>
        <!-- <select class="form-control" id="perend" name="perend" #periodEnd (change)="changePeriod(periodEnd.value)"> -->
        <select class="form-control" name="perend" id="perend" [(ngModel)]="period_end"  (change)="changePeriod($event)">
          <option [value]="'new'"><i>Create New</i></option>
          <option *ngFor="let p of select_periods" [value]="p.date">{{p.date}}</option>
                      
          <!-- <option [ngValue]="'June 30, 2022'">June 30, 2022</option>
          <option [ngValue]="'Dec 31, 2021'">Dec 31, 2021</option> -->
          <!-- <option value="underground">Underground</option> -->
          
        </select> 
      </div>
    </span>
    
  </div>  
</div>

<div class="header-container">
  <nb-actions size="small">

    <!--<nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <!-- [picture]="user?.picture" -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture"
               (menuClick)="onMenuClick($this)">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
