import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NbAuthJWTToken, NbAuthService,NbLogoutComponent } from '@nebular/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalVars } from '../../../common/globals';
import { SharedVarService } from '../../../shared-var.service';
import { ApiService } from '../../../api.service';
import { FormBuilder, FormGroup } from '@angular/forms';



@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  // @ViewChild('periodEnd') periodEnd;
  //fc = new FormControl;
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  //user: any;
  user = {};
  org_id: number;
  role: number;


  bdc: boolean = GlobalVars.bdc;

  period_end: any = GlobalVars.period_end;

  periodForm: FormGroup;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ 
    { title: 'Profile',
      icon: 'person-outline',
      link: ['./pages/admin/company'],
    }, 
    { title: 'Log out',
      icon: 'unlock-outline',
      link: [],
    } 
  ];

  periods: any = [];
  select_periods: any = [];

  constructor(private service: SharedVarService,
              public router: Router,
              private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private authService: NbAuthService,
              public apiService: ApiService,
              private formBuilder: FormBuilder) 
              {
                this.authService.onTokenChange()
                .subscribe((token: NbAuthJWTToken) => {
          
                  if (token.isValid()) {
                    this.user = token.getPayload();
                    this.user["picture"] = "./assets/images/person.png";
                    this.org_id = this.user["org_id"];
                    this.role = this.user["role"];
                    GlobalVars.org_id = this.org_id;
                    GlobalVars.user = this.user;
                    GlobalVars.userId = this.user["ID"];
                    GlobalVars.showAdmin = this.user["role"];
                    console.log(JSON.stringify(this.user)); // here we receive a payload from the token and assigns it to our `user` variable 
                  }
          
                });

                

                this.menuService.onItemClick().subscribe(e => {
                  console.log(e.item);
                  if(e.item.title == "Log out"){
                    this.logout();
                  }
                });
            
              }

  ngAfterViewInit() {
    //console.log(this.periodForm.value);
    //this.periodForm.setValue({perend: this.period_end});
    //document.getElementById('perend')  = this.period_end;

  }

  ngOnInit() {
    

    this.period_end = localStorage.getItem('periodEnd');
    if(this.period_end && this.period_end != ''){
      this.service.setValue(this.period_end);
    }
    console.log('local period end ' + this.period_end);

    this.getPaidservices();

    /* if (!this.period_end || this.period_end == ''){
      this.service.setValue('June 30, 2022');
      this.period_end = this.service.getValue();
      localStorage.setItem('periodEnd',this.period_end);
    }else{
      this.service.setValue(this.period_end);
    } */

    this.currentTheme = this.themeService.currentTheme;

    /*this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);
    */
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  getPaidservices(){
    const self = this;
    this.apiService.getPaidservices(this.period_end).subscribe((response: any) =>{
      if (response.result == 'success'){
        const pds = response.data;
        let pcnt = 0;
        pds.forEach(function(period){
          console.log(period.period_end_date);
          let pd = period.period_end_date.substring(0,period.period_end_date.length - 2) ;
          let pd_date = new Date(pd);
          let m = pd_date.toLocaleString('default', { month: 'short' });
          let d = pd_date.getDate();
          let dt_str = m + ' ' + d + ', ' + pd_date.getFullYear();
          console.log('date string '  + dt_str);
          self.select_periods.push({'date': dt_str});
          if (pcnt == 0){
            console.log('period_end ' + self.period_end);
            if(!self.period_end || self.period_end == ''){
              self.period_end = dt_str;
              //self.service.setValue(dt_str);
              GlobalVars.period_end = dt_str;
            }
          }
        })
        if (self.select_periods.length == 1){
          if (this.period_end != self.select_periods[0].date ){
            const dt_str = self.select_periods[0].date.toString;
            //self.service.setValue(dt_str);
            self.period_end = this.service.getValue();
          }
        }
        if (!self.period_end || self.period_end == ''){
          const dt_str = self.select_periods[0].date.toString;
          this.service.setValue(dt_str);
          this.period_end = this.service.getValue();
          localStorage.setItem('periodEnd',this.period_end);
        }else{
          self.service.setValue(self.period_end);
        }

      }else{

        console.log(response.error);
        alert('error getting periods')
      }
      
    })

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize(null);

    return false;
  } 

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  logout() {
    console.log('asta la vista, baby');
    this.authService.logout('email').subscribe(m => {
      console.log("got here " + JSON.stringify(m));
      this.router.navigate(['/auth/login']);
    });;

  }

  changePeriod(event){
    //console.log(JSON.stringify(e));
    //if (event.target.value.split(': ')[1] == 'new'){
    if (event.target.value == 'new'){
      console.log("going to new filing");
      this.gotoCart();
    }else{
      console.log(event.target.value);
      const e = event.target.value;
      GlobalVars.period_end = e;
      this.period_end = e;
      localStorage.setItem('periodEnd',e);
      this.service.setValue(e);
      console.log("changed");
      this.getServices();
    }
  }

  getServices(){
    const self = this;
    console.log("checking services");
    this.apiService.getFilerservices(this.period_end).subscribe((response: any) =>{
      console.log(response);
      if (response.result == 'success'){
        const services = response.data;
        if(services && services.length > 0){
          // self.showStatus = false;
          services.forEach(function(service){
            if (!service.payment_made){
              
              self.gotoCart();
              return;
            }
            if(service.type == 'BDC'){
              self.bdc = true;
              GlobalVars.bdc = true;
            }
            
            })

        }else{

          
          this.gotoCart();
          

        }

      }else{

        //alert('error checking for services ')
      }

    })

  }

  gotoCart(){
    var url = '../cart/';
    GlobalVars.navPoint = './pages/dashboard/';
    this.router.navigate([url]).then(e =>{
      if (e) {
        console.log("nav success");
      }else{
        console.log("nav fail")
      }
    })

  }

  gotoNewFiling(){

    var url = './pages/newfiling/';
    GlobalVars.navPoint = './pages/dashboard/';
    this.router.navigate([url]).then(e =>{
      if (e) {
        console.log("nav success");
      }else{
        console.log("nav fail")
      }
    })

  }
  
}
