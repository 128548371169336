import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import decode from 'jwt-decode';
import { Observable } from 'rxjs';
@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(' adding token ');
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
    return next.handle(request);
  }
  public getToken(): string {
    return localStorage.getItem('bdcToken');
  }
}
