import { Component } from '@angular/core';
import { ApiService } from '../../../api.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbThemeService, NbColorHelper, NbIconLibraries } from '@nebular/theme';

import { NbWindowRef } from '@nebular/theme';

class Contact {
  name:string = '';
  title:string = '';
  email:string = '';
  phone:number = null;
  extension:number = null;
}

@Component({
  selector: 'ngx-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})


export class ContactComponent {

  cform: FormGroup;
  contact: any = new Contact;
  submitted: boolean = false;

  constructor(public apiService: ApiService, public windowRef: NbWindowRef, private fb: FormBuilder,private theme: NbThemeService,) { 
      this.cform = fb.group({
        name: this.contact.name,
        title: this.contact.title,
        email: this.contact.email,
        phone: this.contact.phone,
        extension: this.contact.extension,
      });
    }

  ngOnInit(): void {


  }

  saveContact(e){
    var self = this;
    console.log('e ' + JSON.stringify(e));
    this.submitted = true;
    console.log('adding ' + JSON.stringify(this.contact));
    this.apiService.addContact(this.contact).subscribe((response: any) => {
      if(response.result == 'success'){
        self.closeWindow();
        

      }else{
        this.submitted = false;
        console.log('Error saving contac ' + response.error);
        alert('Error saving contact ' + response.error);
      }

    })

  }


  onSubmit(e) {
    this.saveContact(e);
  }

  close() {
    this.windowRef.close();
  }

  closeWindow(){
    this.windowRef.close();
  }

}
