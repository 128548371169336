import * as L from 'leaflet';
import {Lead} from '../lead';
export class GlobalVars {

    public static suspended: boolean;
    public static bdc: boolean;

    public static userId: number;
    public static role: number;
    public static period_end:any;

    public static apsComplete:boolean = false;
    public static sitesComplete:boolean = false;
    public static settingsComplete:boolean = false;
    
    public static fcc477Prep:boolean;
    public static bdcPrep:boolean;
    public static statePrep:boolean;

    public static stateComplete:boolean;
    public static fccComplete:boolean;
    public static bdcComplete:boolean;
    public static fiberComplete:boolean;
    public static docsisComplete:boolean;
    public static dslComplete:boolean;
    public static copperComplete:boolean;
    public static cnheatComplete:boolean;

    public static org_id: number;
    public static url: string = 'http://www.regulatorysolutions.us';
    public static leadData: any;
    public static siteData: any;
    public static apData: any;
    public static freqData: any;
    public static basemap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' });
    public static sitesLayer = L.LayerGroup;
    public static leadsLayer = L.LayerGroup;
    public static apsLayer = L.LayerGroup;
    public static ptpLayer  = L.LayerGroup;
    public static mapCenter: any;
    public static mapZoom: any;
    public static mapOptions: any;
    public static mapMode: string;
    public static mapModes = [{'mode':"Normal"},{'mode':"Add Lead"},{'mode':"Quick Check"},{'mode':"What If"}];
    public static mapClickModes = [{'mode':"Normal"},{'mode':"addLead"},{'mode':"moveLead"},{'mode':'ptp1'},{'mode':'ptp2'}];
    public static mapClickmode: string;
    public static bingMapskey: string = 'AtbnqglVZkEBgFD6RbmKGM_0Oql2dMKxk5Kf-eKyLn_rIZhaUxnvBdExbrNFsJD-';

    public static layerControl: any;
    public static geoOptions = {provider: 'openstreetmap',
                                httpAdapter: 'https', // Default
                                apiKey: '', // for Mapquest, OpenCage, Google Premier
                                formatter: null   };

    public static ap: any;
    
    public static bands: any = [
        {'band':'900 MHz', 'lower':'902', 'upper':'928','type':'unlicensed','subbands':[
            
        ] },
        {'band':'2.4 GHz', 'lower':'2412', 'upper':'2462','type':'unlicensed','subbands':[
            
        ] },
        {'band':'3.65 GHz', 'lower':'3655', 'upper':'3695','type':'unlicensed','subbands':[
            
        ] },
        {'band':'5 GHz', 'lower':'', 'upper':'928','type':'unlicensed','subbands':[
          {'band':'U-NII-1','lower':'5150', 'upper':'5250','type':'unlicensed'},
          {'band':'U-NII-2','lower':'5251', 'upper':'5725','type':'unlicensed'},
          {'band':'U-NII-2','lower':'5726', 'upper':'5850','type':'unlicensed'}, 

        ] }
    ]

    public static siteSelected: number = null;
    public static apSelected: number = null;
    public static navPoint: string = null;
    public static freqSelected: string = null;
    public static leadSelected: string = null;

    public static siteRows: number = 10;
    public static subRows: number = 10;
    public static invItemrows : number = null;
    public static siteNamefilter: string = '';
    public static sitePositionfilter: string = '';
    public static siteElvfilter: string = '';
    public static siteTypefilter: string = '';

    public static apRows: number = null;
    public static apNamefilter: string = '';
    public static apSitefilter: string = '';
    public static apElvfilter: string = '';
    public static apPositionfilter: string = '';
    public static apAzimuthfilter: string = '';
    public static apBeamwidthfilter: string = '';
    public static apFrequencyfilter: string = '';
    public static apRadiusfilter: string = '';

    public static contactNamefilter: string = '';
    public static contactPhonefilter: string = '';
    public static contactEmailfilter: string = '';
    public static contactTitlefilter: string = '';
    public static contactExtfilter: string = '';

    public static user: any = {};
    public static showAdmin: string= '';

    public static filerData: any = null;
    public static contacts: any;

    public static lead: Lead;
    public static site: any;

    public static invItem: any;

    public static mapFrom: string;

    public static States:any =  [
        { name: 'ALABAMA', abbreviation: 'AL'},
        { name: 'ALASKA', abbreviation: 'AK'},
        { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
        { name: 'ARIZONA', abbreviation: 'AZ'},
        { name: 'ARKANSAS', abbreviation: 'AR'},
        { name: 'CALIFORNIA', abbreviation: 'CA'},
        { name: 'COLORADO', abbreviation: 'CO'},
        { name: 'CONNECTICUT', abbreviation: 'CT'},
        { name: 'DELAWARE', abbreviation: 'DE'},
        { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
        { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
        { name: 'FLORIDA', abbreviation: 'FL'},
        { name: 'GEORGIA', abbreviation: 'GA'},
        { name: 'GUAM', abbreviation: 'GU'},
        { name: 'HAWAII', abbreviation: 'HI'},
        { name: 'IDAHO', abbreviation: 'ID'},
        { name: 'ILLINOIS', abbreviation: 'IL'},
        { name: 'INDIANA', abbreviation: 'IN'},
        { name: 'IOWA', abbreviation: 'IA'},
        { name: 'KANSAS', abbreviation: 'KS'},
        { name: 'KENTUCKY', abbreviation: 'KY'},
        { name: 'LOUISIANA', abbreviation: 'LA'},
        { name: 'MAINE', abbreviation: 'ME'},
        { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
        { name: 'MARYLAND', abbreviation: 'MD'},
        { name: 'MASSACHUSETTS', abbreviation: 'MA'},
        { name: 'MICHIGAN', abbreviation: 'MI'},
        { name: 'MINNESOTA', abbreviation: 'MN'},
        { name: 'MISSISSIPPI', abbreviation: 'MS'},
        { name: 'MISSOURI', abbreviation: 'MO'},
        { name: 'MONTANA', abbreviation: 'MT'},
        { name: 'NEBRASKA', abbreviation: 'NE'},
        { name: 'NEVADA', abbreviation: 'NV'},
        { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
        { name: 'NEW JERSEY', abbreviation: 'NJ'},
        { name: 'NEW MEXICO', abbreviation: 'NM'},
        { name: 'NEW YORK', abbreviation: 'NY'},
        { name: 'NORTH CAROLINA', abbreviation: 'NC'},
        { name: 'NORTH DAKOTA', abbreviation: 'ND'},
        { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
        { name: 'OHIO', abbreviation: 'OH'},
        { name: 'OKLAHOMA', abbreviation: 'OK'},
        { name: 'OREGON', abbreviation: 'OR'},
        { name: 'PALAU', abbreviation: 'PW'},
        { name: 'PENNSYLVANIA', abbreviation: 'PA'},
        { name: 'PUERTO RICO', abbreviation: 'PR'},
        { name: 'RHODE ISLAND', abbreviation: 'RI'},
        { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
        { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
        { name: 'TENNESSEE', abbreviation: 'TN'},
        { name: 'TEXAS', abbreviation: 'TX'},
        { name: 'UTAH', abbreviation: 'UT'},
        { name: 'VERMONT', abbreviation: 'VT'},
        { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
        { name: 'VIRGINIA', abbreviation: 'VA'},
        { name: 'WASHINGTON', abbreviation: 'WA'},
        { name: 'WEST VIRGINIA', abbreviation: 'WV'},
        { name: 'WISCONSIN', abbreviation: 'WI'},
        { name: 'WYOMING', abbreviation: 'WY' }
    ];


    

      
    public static siteTitle: string = "This is example of ItSolutionStuff.com";
}