
<div class="row">
  <div class="col-12">

<form [formGroup]="cform" (ngSubmit)="onSubmit($event)" class="form">
  <div class="form-group">
    <label for="name">Name:</label>
    <input nbInput id="name" type="text" formControlName="name" [(ngModel)]="contact.name" required/>
  </div>
  <div class="form-group">
    <label for="title">Title:</label>
    <input nbInput id="title" type="text" formControlName="title" [(ngModel)]="contact.title" required/>
  </div>
  <div class="form-group">

    <label for="email">Email:</label>
    <input nbInput id="email" type="text" formControlName="email" [(ngModel)]="contact.email" required/>
  </div>
  <div class="form-group">
    <label for="phone">Phone:</label>
    <input nbInput id="phone" type="text" formControlName="phone" [(ngModel)]="contact.phone" required/>
  </div>
  <div class="form-group">
    <label for="extension">Extension:</label>
    <input nbInput id="extension" type="text" formControlName="extension" [(ngModel)]="contact.extension" required/>
  </div>

    <button nbButton type="submit" status="success">Submit</button>
  </form>

  </div>
</div>

